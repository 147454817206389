import { useEffect, useState, useMemo } from 'react';
import { useRouter } from 'next/router';

import { isSimpleInput } from '../humanity/components/inputWithConditionalInputs/isSimpleInput';
import { SignupStepFragment } from '../humanity/components/signupStep/signupStep.fragment';

import useSignupModal from 'hooks/useSignupModal';
import {
  CURRENT_EMPLOYEE_ROLES,
  FAST_TRACK_STORAGE_KEY,
  NON_OWNER_REDIRECT_PATH,
  NON_OWNER_ROLES,
} from 'utils/signupConstants';
import FastTrackClient from 'apiClients/fastTrack';
import { useHubspotForm } from 'hooks/useHubspotForm';
import { track } from 'utils/analytics';
import { useRecaptcha } from 'hooks/useRecaptcha';
import { logger } from 'utils/logger';
import { trackEmailCollected } from 'utils/emailCollected';
import useVwo from 'hooks/useVwo';
import useAnalytics from 'hooks/useAnalytics';

const handleFastTrackQualified = async (vals) => {
  track('self_service_qualified', vals);
  const res = await FastTrackClient.create(vals);

  return res;
};

const setValsInStorage = (vals) => {
  try {
    sessionStorage.setItem(FAST_TRACK_STORAGE_KEY, JSON.stringify(vals));
  } catch (err) {
    logger.warn('Error setting fast track values in storage', err);
  }
};

export const useFastTrack = ({
  steps,
  formMetadata,
  employeeMaximum = null,
  fastTrackPayrolls,
}: {
  steps: SignupStepFragment[];
  formMetadata: {
    hubspotFormId: string;
    hubspotSandboxFormId: string;
    salesforceCampaignId: string;
    formType: string;
  };
  employeeMaximum?: number | null;
  fastTrackPayrolls: string[];
}) => {
  const { hubspotFormId, hubspotSandboxFormId, salesforceCampaignId, formType } =
    formMetadata;

  const vwoClient = useVwo();
  const {
    analytics: { hinAnonymousId },
  } = useAnalytics();
  const { submitLead, bookingUrl, showModal, onModalClose } = useSignupModal();
  const { submitForm: submitHubspotForm } = useHubspotForm(
    hubspotFormId,
    hubspotSandboxFormId,
    salesforceCampaignId,
    formType
  );
  const [initialValues, setInitialValues] = useState<
    ({ stepNumber: number } & Record<string, unknown>) | undefined
  >(undefined);
  // generate empty initial value for each input in our steps
  // setting this prevents weird behavior with selects and avoids inputs
  // switching from uncontrolled to controlled
  const emptyInitialValues = useMemo(
    () =>
      steps
        .flatMap((step) => step.inputsCollection.items)
        .flatMap((input) =>
          isSimpleInput(input)
            ? [input]
            : [input.input, ...input.conditionallyShownInputsCollection.items]
        )
        .reduce(
          (prev, curr) => ({
            ...prev,
            [curr?.fieldName]: '',
          }),
          { stepNumber: 0 }
        ),
    [steps]
  );
  const { handleSubmitWithRecaptcha } = useRecaptcha();
  const router = useRouter();

  useEffect(() => {
    // check for values set in session storage from a masthead form submission
    const getValsFromStorage = () => {
      try {
        const storageVals = sessionStorage.getItem(FAST_TRACK_STORAGE_KEY);

        if (storageVals) {
          setInitialValues({
            ...emptyInitialValues,
            ...JSON.parse(storageVals),
          });
        } else {
          setInitialValues({ ...emptyInitialValues });
        }
      } catch (err) {
        setInitialValues({ ...emptyInitialValues });
      }
    };

    getValsFromStorage();
  }, [emptyInitialValues]);

  const _handleNextStep = async (
    vals: Record<string, string> & { stepNumber: number }
  ) => {
    setValsInStorage({ ...vals });

    if (vals.role && CURRENT_EMPLOYEE_ROLES.includes(vals.role)) {
      await submitLead(vals, formMetadata);
      return false;
    }

    // Only submit to HS if submitLead will not be called, since it will be sent there
    submitHubspotForm(vals);

    if (vals.role && NON_OWNER_ROLES.includes(vals.role)) {
      track('conversion_partner', vals);

      await router.push(NON_OWNER_REDIRECT_PATH);

      return false;
    }

    if (vals.stepNumber === 1 && vals.email) {
      trackEmailCollected(vals, vwoClient, hinAnonymousId);
    }
    const employeeMaxValue = vals.employeesRange
      ? Number(vals.employeesRange.match(/(\d+)(?!.*\d)/)?.[0]) // Regex gets last number in string
      : 0;

    if (
      (vals.stepNumber >= steps.length - 1 || steps[vals.stepNumber]?.redirectOnSubmit) &&
      vals.has_retirement_plan === 'no' &&
      vals.is_controlled_group === 'no' &&
      (vals.non_profit === 'no' || vals.isChurch === 'no') &&
      vals.union_employees === 'no' &&
      (employeeMaximum === null ||
        (vals.employeesRange?.length && employeeMaxValue <= employeeMaximum)) &&
      (fastTrackPayrolls === null ||
        (vals.payrollProvider && fastTrackPayrolls.includes(vals.payrollProvider)))
    ) {
      // redirect user to admin onboarding if qualified
      const { redirectUrl } = await handleFastTrackQualified(vals);

      window.location.href = redirectUrl;
      return false;
    }

    if (vals.non_profit === 'yes' && vals.isChurch === 'yes') {
      await submitLead(vals, formMetadata);
      return false;
    }

    return true;
  };

  const handleNextStep = async (vals) =>
    handleSubmitWithRecaptcha(_handleNextStep, vals, 'fast_track_step');

  const _handleSubmit = async (vals) => {
    await submitLead(vals, formMetadata);
  };

  const handleSubmit = async (vals) =>
    handleSubmitWithRecaptcha(_handleSubmit, vals, 'fast_track_submit');

  return {
    initialValues,
    handleNextStep,
    handleSubmit,
    bookingUrl,
    showModal,
    onModalClose,
  };
};
