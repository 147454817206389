import { Document } from '@contentful/rich-text-types';

import { ContentfulSysInterface } from '../../../types/common/contentfulSys.types';

import {
  InputOptionFragment,
  inputOptionFragment,
} from 'humanity/components/formikInput/inputOption.fragment';

export const inputFragment = ({ optionsLimit = 10, withImage = false } = {}) => `
  ... on Input {
    sys {
      id
    }
    type
    label
    required
    fieldName
    placeholder
    width
    autocompleteValue
    tooltip {
      json
    }
    defaultValue {
      ${inputOptionFragment(withImage)}
    }
    optionsCollection(limit: ${optionsLimit}) {
      items {
        ${inputOptionFragment(withImage)}
      }
    }
  }
`;

export type InputFragment = ContentfulSysInterface & {
  type: 'text' | 'number' | 'email' | 'tel' | 'date' | 'select' | 'radio';
  label: string;
  required: boolean;
  fieldName: string;
  placeholder?: string | null;
  width: 'full' | 'half' | 'third';
  autocompleteValue?: string | null;
  tooltip?: {
    json: Document;
  } | null;
  defaultValue?: InputOptionFragment;
  optionsCollection?: {
    items: InputOptionFragment[];
  };
};
