import PropTypes from 'prop-types';

import {
  contentfulDisclosuresType,
  contentfulLayoutType,
  contentfulSeoMetadataType,
  defaultPageType,
  lcCollectionPageType,
  semLandingPageType,
} from 'types';
import { contentfulSysType } from 'types/common/contentfulSys.types';
import { signupPageType } from 'types/pages/signupPage.types';

/**
 * @deprecated
 * Use pageSettingsType along with the specific page content type
 * in future pages to avoid importing unnecessary proptypes
 */
export const pageContainerType = PropTypes.shape({
  sys: contentfulSysType.isRequired,
  internalTitle: PropTypes.string.isRequired,
  slug: PropTypes.string.isRequired,
  seoMetadata: contentfulSeoMetadataType.isRequired,
  layout: contentfulLayoutType.isRequired,
  page: PropTypes.oneOfType([
    lcCollectionPageType,
    semLandingPageType,
    defaultPageType,
    signupPageType,
  ]).isRequired,
  notes: contentfulDisclosuresType,
  disclosures: contentfulDisclosuresType,
});

/**
 * @deprecated
 * Import from types/common/pageSettings.types instead
 */
export const pageSettingsType = PropTypes.shape({
  sys: contentfulSysType.isRequired,
  internalTitle: PropTypes.string.isRequired,
  slug: PropTypes.string.isRequired,
  seoMetadata: contentfulSeoMetadataType.isRequired,
  layout: contentfulLayoutType.isRequired,
  notes: contentfulDisclosuresType,
  disclosures: contentfulDisclosuresType,
});
